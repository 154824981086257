<template>
  <v-card flat width="700px" ref="top">
    <v-card-text>
      <v-container fluid>
        <v-row wrap justify="center">
          <v-col cols="12">
            <p class="ma-n1">
              <a @click="reloadPage"
                ><v-img
                  @click="reloadPage"
                  max-width="100px"
                  src="3sigma.png"
                ></v-img
              ></a>
            </p>
            <p class="text-h5">3 Sigma Surgeon</p>
            <div v-show="!geekMode && !hasReport">
              <p class="mb-1">
                Let 3σ help you make statistically informed conclusions about
                your surgical performance. Supercharge your audits today!
              </p>
              <p class="mb-1">Report your outcomes with 95% CI</p>
              <p class="mb-1">Put a probability on bad runs or lucky streaks</p>
              <p class="mb-1">
                Know when you have enough numbers to eliminate chance
                occurrences
              </p>
              <p class="mb-1">
                Benchmark your surgery against existing standards
              </p>
              <p class="mb-1">
                <router-link to="/learn">Learn more</router-link>
              </p>
            </div>
            <div v-show="geekMode && !hasReport">
              <p class="mb-1">
                Using the Agresti-Coull interval method, the calculator provides
                the confidence interval for this series's proportion (x/n).
              </p>
              <p class="mb-1">
                This calculator then applies Binomial distribution or Poisson
                distribution to derive the probability of observing x number of
                events in n number of surgeries, assuming the probability of the
                event occurring is p.
              </p>
              <p class="mb-1">
                It will also provide the number of n when the probability
                distribution approximates the normal distribution.
              </p>
              <p class="mb-1">
                If there are sufficient numbers, 3σ will generate the range of
                expected events for three standard deviations from the mean.
              </p>
              <p class="mb-1">
                <router-link to="/learn">Learn more</router-link>
              </p>
            </div>
            <v-switch
              v-show="!hasReport"
              class="mt-n1"
              v-model="geekMode"
              :label="geekMode ? 'Stat-speak' : 'English'"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-show="!hasReport">
          <v-col>
            <v-card width="100%">
              <v-card-title class="mb-n3">Input</v-card-title>
              <v-card-text
                ><v-form ref="form" :lazy-validation="true">
                  <v-container>
                    <v-row class="mb-n6"
                      ><v-col>
                        <v-text-field
                          :disabled="inProgress"
                          dense
                          outlined
                          :rules="integerRules"
                          required
                          v-model="n"
                          label="cases (n)"
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-text-field
                          :disabled="inProgress"
                          dense
                          outlined
                          :rules="integerRulesAllowZero"
                          required
                          v-model="x"
                          label="events (x)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n6">
                      <v-col>Reported {{ p_label }} of event (P)</v-col>
                    </v-row>
                    <v-row class="mb-n8"
                      ><v-col>
                        <p v-show="pType == 'p'">
                          <v-text-field
                            :disabled="inProgress"
                            dense
                            outlined
                            :rules="numRules"
                            required
                            v-model="p"
                            label="probability"
                            @keyup="update_p_prob"
                          >
                          </v-text-field>
                        </p>

                        <p v-show="pType == 'r'">
                          <v-text-field
                            :disabled="inProgress"
                            dense
                            outlined
                            v-model="p_ratio"
                            label="ratio"
                            @keyup="update_p_ratio"
                          ></v-text-field>
                        </p>

                        <p v-show="pType == 'pct'">
                          <v-text-field
                            :disabled="inProgress"
                            dense
                            outlined
                            v-model="p_pct"
                            label="%"
                            @keyup="update_p_pct"
                          ></v-text-field>
                        </p>
                      </v-col>
                      <v-col>
                        <!-- <v-radio-group
                          class="mt-0"
                          v-model="pType"
                          row
                          dense
                          :disabled="inProgress"
                        >
                          <v-radio label="probability" value="p"></v-radio>
                          <v-radio label="ratio" value="r"></v-radio>
                          <v-radio label="%" value="pct"></v-radio>
                        </v-radio-group> -->

                        <v-select
                          v-model="pType"
                          :items="pChoices"
                          item-text="label"
                          item-value="value"
                          label="Select"
                          single-line
                          outlined
                          dense
                          :disabled="inProgress"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      ><v-col cols="6">
                        <v-text-field
                          :disabled="inProgress"
                          dense
                          outlined
                          v-model="n_singular"
                          label="Name your case (n)"
                          hint="(Optional)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-n5"
                      ><v-col cols="6">
                        <v-text-field
                          :disabled="inProgress"
                          dense
                          outlined
                          v-model="x_singular"
                          label="Name your event (x)"
                          hint="(Optional)"
                          append-outer-icon="mdi-magnify"
                          v-on:click:append-outer="queryPaper"
                        >
                        </v-text-field>
                      </v-col>

                      <v-text-field
                        v-show="false"
                        v-model="valuesURL"
                        id="linkURL"
                      >
                      </v-text-field>
                    </v-row>
                    <v-row class="mb-n6 mt-n6"
                      ><v-col align="left">
                        <p class="mb-1">
                          <v-btn
                            color="primary"
                            @click="calc"
                            :disabled="inProgress"
                            >Benchmark Me</v-btn
                          >
                          &nbsp;
                          <v-progress-circular
                            indeterminate
                            v-show="inProgress"
                            size="20"
                          ></v-progress-circular>
                        </p>
                        <p></p>
                      </v-col>
                      <v-col align="right"
                        ><p>
                          <v-btn @click="shareLink"
                            >Share Values Via Link</v-btn
                          >
                        </p>
                        <p>
                          <v-btn @click="showEx">Show examples</v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="hasResult && hasReport" ref="downloadreport">
          <v-col>
            <p class="mt-3 text-h6">
              Download your report with printable graphs
              <a :href="answer.report_filename">here.</a>
            </p>
            <p class="mt-n3 text-subtitle-1">
              Thank you for your contribution.
            </p>
          </v-col>
        </v-row>

        <v-row v-show="hasResult && !hasReport" ref="compareproportion">
          <v-col>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col> To start the whole output: </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" xs="12" sm="4" md="4">
                      <p class="text-h4">
                        {{ pctToString(answer.events_per_case) }}
                      </p>
                      <p class="ma-1">
                        Your percentage of <i>{{ x_singular }}</i>
                      </p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4">
                      <p class="text-h6 mb-0">vs</p>
                    </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4">
                      <p class="text-h4">
                        {{ pctToString(p) }}
                      </p>
                      <p class="ma-1">
                        Reference percentage of <i>{{ x_singular }}</i>
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="hasResult && !hasReport" ref="proportion"
          ><v-col>
            <v-card>
              <v-card-title class="text-h6"
                ><span class="nowrap"
                  >Binomial Proportion Confidence Interval</span
                ></v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      ><div v-html="answer.prop_estimation_text"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row
          v-show="answer.recommendation == 'poisson' && hasResult && !hasReport"
          ><v-col>
            <v-card>
              <v-card-title
                ><span class="nowrap"
                  >Binomial or Poisson Distribution</span
                ></v-card-title
              >
              <v-card-text>
                <v-container
                  ><v-row
                    ><v-col>
                      <div v-html="answer.binom_or_poisson_text"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row
          v-show="
            answer.recommendation == 'binomial' && hasResult && !hasReport
          "
        >
          <v-col>
            <v-card>
              <v-card-title
                ><span class="nowrap"
                  >Binomial Probability Distribution</span
                ></v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row
                    ><v-col><div v-html="answer.binom_prob_text"></div> </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-card max-width="250px" flat>
                        <v-card-text>
                          <!-- <div class="flex"> -->
                          <v-data-table
                            :mobile-breakpoint="0"
                            dense
                            :items="answer.binom"
                            :headers="poissonHeader"
                            :hide-default-footer="true"
                          >
                            <template v-slot:[`item.value`]="{ item }">
                              {{ pctToString(item.value) }}
                            </template>
                          </v-data-table>
                          <!-- </div> -->
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-img
                        :src="binomial_chart"
                        contain
                        height="300px"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row
          v-show="answer.recommendation == 'poisson' && hasResult && !hasReport"
          ><v-col>
            <v-card>
              <v-card-title
                ><span class="nowrap"
                  >Poisson Probability Distribution</span
                ></v-card-title
              >
              <v-card-text>
                <v-container>
                  <!-- <v-row v-if="n < answer.min_cases_for_binomial">
                    <v-col
                      ><p>
                        Minimum
                        <b>{{
                          valueToInteger(answer.min_cases_for_binomial)
                        }}</b>
                        {{ answer.n_plural }} (n) required to perform normal
                        approximation for binomial distribution. If np+/-2*(npq)
                        is between 0 and n then binomial approximation can be
                        used.
                      </p>
                    </v-col>
                  </v-row> -->
                  <v-row>
                    <v-col>
                      <v-card max-width="200px" flat>
                        <v-card-text>
                          <v-data-table
                            dense
                            :mobile-breakpoint="0"
                            :items="answer.poisson"
                            :headers="poissonHeader"
                            :hide-default-footer="true"
                          >
                            <template v-slot:[`item.value`]="{ item }">
                              {{ pctToString(item.value) }}
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      ><v-img
                        :src="poisson_chart"
                        contain
                        height="300px"
                      ></v-img
                    ></v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <div v-html="answer.poisson_prob_text"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row v-show="answer.n_plural && hasResult && !hasReport">
          <v-col>
            <v-card>
              <v-card-title>
                <span class="nowrap"
                  >Approximation to Normal Distribution</span
                ></v-card-title
              >
              <v-card-text>
                <v-container>
                  <v-row
                    ><v-col
                      ><div v-html="answer.sufficient_n_text"></div>
                      <p
                        class="mt-2"
                        v-show="!hasMinimumNforNormal && !showNormalDist"
                      >
                        <v-btn @click="showNormalDist = true"
                          >Show Preliminary Modelling</v-btn
                        >
                      </p>
                    </v-col>
                  </v-row>

                  <v-row v-show="hasMinimumNforNormal || showNormalDist"
                    ><v-col>
                      <v-card max-width="300px" flat>
                        <v-card-text>
                          <v-data-table
                            :mobile-breakpoint="0"
                            dense
                            :items="answer.binomial_stddev_boundary"
                            :headers="binomialStdDevHeader"
                            :hide-default-footer="true"
                          >
                            <template v-slot:[`item.text`]="{ item }">
                              {{ valueToInteger(item.text) }}
                            </template>

                            <template v-slot:[`item.sigma`]="{ item }">
                              {{ item.sigma == "mean" ? "mean" : item.sigma }}
                            </template>
                          </v-data-table>
                          <div class="text-caption">σ = Standard Deviation</div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <v-row v-show="hasMinimumNforNormal || showNormalDist">
                    <v-col><div v-html="answer.sd_text"></div></v-col>
                  </v-row>

                  <v-row v-show="hasMinimumNforNormal || showNormalDist">
                    <v-col
                      ><v-img :src="normal_chart" contain height="300px"></v-img
                    ></v-col>
                  </v-row>

                  <v-row v-show="hasMinimumNforNormal || showNormalDist">
                    <v-col><div v-html="answer.sd_pct_text"></div></v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row v-show="hasResult && hasResult && !hasReport"
          ><v-col>
            <v-card ma>
              <v-card-title
                ><span class="nowrap">Summary Statement</span>
                <v-btn
                  fab
                  text
                  small
                  @click="copyDivContentToClipboard('summary')"
                >
                  <v-icon small> mdi-content-copy </v-icon></v-btn
                ></v-card-title
              >
              <v-card-text>
                <!-- &nbsp;<v-icon @click="copySummary" small>mdi-content-copy</v-icon> -->
                <div v-html="answer.summary_text" id="summary"></div>
              </v-card-text>
            </v-card> </v-col
        ></v-row>

        <v-row v-show="hasResult && !hasReport">
          <v-col>
            <v-card>
              <v-card-title>Monte Carlo Simulation</v-card-title>
              <v-card-text>
                <monte-carlo-viewer
                  v-bind:p="parseFloat(p)"
                  v-bind:userP="parseFloat(answer.events_per_case)"
                  v-bind:x_singular="x_singular"
                  v-bind:n_singular="n_singular"
                  v-bind:x_plural="answer.x_plural"
                  v-bind:n_plural="answer.n_plural"
                ></monte-carlo-viewer>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-show="hasResult && !hasReport">
          <v-col>
            <v-card ma>
              <v-card-title>
                <span class="nowrap">Source</span>
              </v-card-title>
              <v-card-text>
                <p>
                  Help us help others. Share with us the PubMed PMID that has
                  the benchmark proportion.
                </p>
                <p>
                  <v-text-field
                    dense
                    outlined
                    v-model="pmid"
                    label="PubMed PMID"
                  >
                    <template v-slot:append-outer
                      ><v-btn small @click="getPubMed">Get</v-btn></template
                    >
                  </v-text-field>
                </p>
                <p v-show="pubmedPaperCitation != ''">
                  <v-textarea
                    outlined
                    readonly
                    dense
                    v-model="pubmedPaperCitation"
                    label="PubMed Preview"
                    rows="10"
                    class="text-subtitle-1"
                  ></v-textarea>

                  <v-container>
                    <v-form ref="submitpaper" @submit.prevent>
                      <v-row class="mb-n6"
                        ><v-col>
                          <v-text-field
                            :disabled="inProgressPaper"
                            dense
                            outlined
                            :rules="integerRules"
                            required
                            v-model="paper_n"
                            label="cases in paper (n)"
                          ></v-text-field>
                        </v-col>

                        <v-col>
                          <v-text-field
                            :disabled="inProgressPaper"
                            dense
                            outlined
                            :rules="integerRulesAllowZeroPaper"
                            required
                            v-model="paper_x"
                            label="events in paper(x)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mb-n6">
                        <v-col>Reported {{ p_label }} of event (P)</v-col>
                      </v-row>
                      <v-row class="mb-n8"
                        ><v-col>
                          <p v-show="pType == 'p'">
                            <v-text-field
                              :disabled="inProgressPaper"
                              dense
                              outlined
                              :rules="numRules"
                              required
                              v-model="p"
                              label="probability"
                              @keyup="update_p_prob"
                            >
                            </v-text-field>
                          </p>

                          <p v-show="pType == 'r'">
                            <v-text-field
                              :disabled="inProgressPaper"
                              dense
                              outlined
                              v-model="p_ratio"
                              label="ratio"
                              @keyup="update_p_ratio"
                            ></v-text-field>
                          </p>

                          <p v-show="pType == 'pct'">
                            <v-text-field
                              :disabled="inProgressPaper"
                              dense
                              outlined
                              v-model="p_pct"
                              label="%"
                              @keyup="update_p_pct"
                            ></v-text-field>
                          </p>
                        </v-col>
                        <v-col>
                          <!-- <v-radio-group
                          class="mt-0"
                          v-model="pType"
                          row
                          dense
                          :disabled="inProgress"
                        >
                          <v-radio label="probability" value="p"></v-radio>
                          <v-radio label="ratio" value="r"></v-radio>
                          <v-radio label="%" value="pct"></v-radio>
                        </v-radio-group> -->

                          <v-select
                            v-model="pType"
                            :items="pChoices"
                            item-text="label"
                            item-value="value"
                            label="Select"
                            single-line
                            outlined
                            dense
                            :disabled="inProgressPaper"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row
                        ><v-col cols="6">
                          <v-text-field
                            :disabled="inProgressPaper"
                            dense
                            outlined
                            v-model="n_singular"
                            label="Case Name in Paper (n)"
                            hint="(Optional)"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mt-n5"
                        ><v-col cols="6">
                          <v-text-field
                            :disabled="inProgressPaper"
                            dense
                            outlined
                            v-model="x_singular"
                            label="Event Name in Paper (x)"
                            hint="(Optional)"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <!-- <v-row class="mt-n5"
                        ><v-col>
                          <v-text-field
                            outlined
                            dense
                            v-model="pubmedContributorEmail"
                            label="Your Email"
                            hint="Enter your email to be notified of updates to your paper."
                            persistent-hint
                            >Email</v-text-field
                          >
                        </v-col>
                      </v-row> -->

                      <v-row class="mt-n3"
                        ><v-col>
                          <v-btn @click="submitToOPS">Submit</v-btn> &nbsp;
                          <v-progress-circular
                            indeterminate
                            v-show="inProgressPaper"
                            size="20"
                          ></v-progress-circular> </v-col
                      ></v-row>
                    </v-form>
                  </v-container>
                </p> </v-card-text
            ></v-card>
          </v-col>
        </v-row>
        <!-- <v-row v-show="hasResult && hasResult && !hasReport"
          ><v-col>
            <v-card ma>
              <v-card-title
                ><span class="nowrap">Submit Reference</span>
                </v-card-title
              >
              <v-card-text>
                <p class="text-h6">Do you know ? </p>
                <blockquote class="blockquote mt-n6">
                  Conscientious registrar has spent 200 hours the best papers to cite when investigating the proportion of patients having success following endoscopic DCR
                </blockquote >
                <p>Let us stop research waste today.</p>
                <p>Share with us which paper you referenced as benchmarking standard.</p>
                <p>Fill in this simple form and in return get a link with high-res, publication ready charts &amp; reports.</p>
                <p><v-btn @click="showSubmit=true">Submit Reference</v-btn></p>
              </v-card-text>
            </v-card> </v-col
        ></v-row>         -->

        <v-row v-show="hasResult && !hasReport">
          <v-col>
            <p class="mb-n1">
              <b> Cite us: </b>
              <v-btn
                fab
                text
                small
                @click="copyDivContentToClipboard('citation')"
              >
                <v-icon small> mdi-content-copy </v-icon></v-btn
              >
            </p>
            <code>
              <span id="citation"
                >3 Sigma Surgeon [Internet]. 2022 [cited {{ citationDate }}].
                Available from: https://surgeon.3sigma.me/</span
              >
            </code>
            <p class="mt-6 text-h6" v-show="!hasReport">
              Looking to include 3σ Surgeon results in your publication ? &nbsp;
              <span class="nowrap">
                <a @click="showRequest = true">Click here</a></span
              >
            </p>
          </v-col>
        </v-row>
      </v-container>

      <v-snackbar v-model="snackbar">
        {{ snackbarText }}
      </v-snackbar>
    </v-card-text>

    <v-dialog v-model="showExamples">
      <tutorial v-bind:navigateToExample="true"></tutorial>
    </v-dialog>

    <!-- <v-dialog v-model="showSubmit">
      <submit></submit>
    </v-dialog>     -->

    <v-dialog v-model="showRequest" max-width="500px">
      <request
        v-bind:x="x"
        v-bind:p="p"
        v-bind:n="n"
        v-bind:x_singular="x_singular"
        v-bind:n_singular="n_singular"
        v-bind:desirable="desirable"
        v-on:success="requestSuccess"
      >
      </request>
    </v-dialog>

    <v-dialog v-model="showSuggestedValue" max-width="70%">
      <v-card>
        <v-card-title>Swap with 1-p</v-card-title>
        <v-card-text>
          <p>
            The probability (p) of your event is more than 0.5. To enable the
            best modeling, we recommend using (1-p) as your (p) and (n-x) as
            your (x).
          </p>

          <p>
            E.g. If you had 60 successes in 80 surgeries and the probability of
            success is 0.85, we recommend modeling the failure instead. We
            recommend using 20 failures in 80 surgeries with the probability of
            failure of 0.15.
          </p>

          <p>We have done this for you based on what you have entered.</p>

          <p class="mb-n1"><b>Your input:</b></p>
          <p class="mb-n1">p={{ p }}, n={{ n }}, x={{ x }}</p>
          <p class="mb-n1">Case Name: {{ n_singular }}</p>
          <p class="mb-n1">Event Name: {{ x_singular }}</p>
          <p>Event is <span v-show="!desirable">not</span> desirable</p>

          <p class="mb-n1"><b>Suggested input:</b></p>
          <p class="mb-n1">
            p={{ numToPrecision((100 - p_pct) / 100, 4) }}, n={{ n }}, x={{
              n - x
            }}
          </p>
          <p class="mb-n1">Case Name: {{ n_singular }}</p>
          <p class="mb-n1">Event Name: no {{ x_singular }}</p>
          <p>Event is <span v-show="desirable">not</span> desirable</p>
          <v-btn @click="acceptSwap">Accept</v-btn>
          <v-btn @click="rejectSwap">Reject</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showSearchResult" max-width="1000px">
      <v-card>
        <v-card-title>Search Result</v-card-title>
        <v-card-text>
          <search-table
            v-bind:data="searchData"
            v-on:use="useOPSValues"
          ></search-table> </v-card-text
      ></v-card>
    </v-dialog>

    <v-dialog v-model="showFindingsSubmitSuccess" max-width="500px">
      <v-card>
        <v-card-title>Paper Findings Submitted</v-card-title>
        <v-card-text>
          <p>
            Thank you for your submission. Use the following link to download
            high-res publication ready charts.
          </p>
          <p>
            Click <a target="_blank" :href="highResURL">HERE</a> for high-res,
            publication ready charts.
          </p>
          <p>
            <v-btn @click="showFindingsSubmitSuccess = false">Close</v-btn>
          </p>
        </v-card-text></v-card
      >
    </v-dialog>
  </v-card>
</template>

<script>
import axiosConfig from "../axios";
import Tutorial from "../components/Tutorial.vue";
import {
  copyContent2Clipboard2,
  isUndefined,
  copyDiv2Clipboard2,
  getPMID,
  parsePMIDXML,
} from "../utils";
import moment from "moment";
import Request from "../components/Request.vue";
import SearchTable from "../components/SearchTable.vue";
import MonteCarloViewer from "../components/MonteCarloViewer.vue";
const _ = require("lodash");
// import Submit from '../components/Submit.vue';

var Fraction = require("fraction.js");

var default_answer = {
  agresti_coull_confidence_interval_for_p: { lower: null, upper: null },
  binomial_test_probability: null,
  poisson: [],
  summary_text: "",
  minimum_n: null,
  enough_cases_for_normal_dist_approx: null,
  binomial_stddev_boundary: [],
};

export default {
  name: "Home",
  components: { Tutorial, Request, SearchTable, MonteCarloViewer },
  mounted: function () {
    this.n = 20;
    this.p = 0.5;
    this.update_p_prob();
    this.x = 2;
    this.x_singular = "failure";
    this.n_singular = "surgery";
    this.plural = null;
    this.desirable = false;

    var run = false;

    if (!isUndefined(this.$route.query.n)) {
      this.n = this.$route.query.n;
      run = true;
    }
    if (!isUndefined(this.$route.query.x)) {
      this.x = this.$route.query.x;
      run = true;
    }
    if (!isUndefined(this.$route.query.p)) {
      this.p = this.$route.query.p;
      this.update_p_prob();
      run = true;
    }
    if (!isUndefined(this.$route.query.n_singular)) {
      this.n_singular = this.$route.query.n_singular;
      run = true;
    }
    if (!isUndefined(this.$route.query.x_singular)) {
      this.x_singular = this.$route.query.x_singular;
      run = true;
    }
    if (!isUndefined(this.$route.query.desirable)) {
      this.desirable = this.$route.query.desirable === "true";
      run = true;
    }
    if (!isUndefined(this.$route.query.download_key)) {
      this.download_key = this.$route.query.download_key;
      run = true;
    }
    var self = this;

    if (run) {
      sleep(500).then(() => {
        self.calc();
      });
    }
  },
  computed: {
    p_label: function () {
      if (this.pType == "p") {
        return "probability";
      } else if (this.pType == "pct") {
        return "percentage";
      } else if (this.pType == "r") {
        return "ratio";
      }
      return "";
    },
    citationDate: function () {
      return moment().format("YYYYMMMD");
    },
    absMaxSD: function () {
      try {
        return Math.abs(this.answer.max_sd);
      } catch {
        return "";
      }
    },
    hasResult: function () {
      if (
        (this.answer.poisson.length == 0 &&
          this.answer.binomial_stddev_boundary.length == 0) ||
        this.hasReport == true
      ) {
        return false;
      } else {
        return true;
      }
    },
    valuesURL: function () {
      var a = process.env.VUE_APP_WEB_URL;
      return (
        a +
        `/#/?n=${encodeURI(this.n)}&p=${encodeURI(this.p)}&x=${encodeURI(
          this.x
        )}&x_singular=${encodeURI(this.x_singular)}&n_singular=${encodeURI(
          this.n_singular
        )}&desirable=${encodeURI(this.desirable)}`
      );
    },
    hasMinimumNforNormal: function () {
      return this.answer.enough_cases_for_normal_dist_approx;
    },

    hasReport: function () {
      return this.answer.report_filename;
    },
  },
  data: function () {
    return {
      n: null,
      x: null,
      p: null,
      p_ratio: null,
      p_pct: null,
      x_singular: "",
      n_singular: "",
      plural: null,
      inProgress: false,
      snackbarText: "",
      snackbar: false,
      showExamples: false,
      showSuggestedValue: false,
      showNormalDist: false,
      showRequest: false,
      showSubmit: false,
      showMetaQuery: false,
      showSearchResult: false,
      showFindingsSubmitSuccess: false,
      geekMode: false,
      desirable: false,
      download_key: "",
      searchData: [],
      paper: {},
      pType: "pct",
      pmid: "",
      pubmedPaperCitation: "",
      pubmedContributorEmail: "",
      paper_n: null,
      paper_x: null,
      inProgressPaper: false,
      highResURL: "",
      pChoices: [
        { label: "%", value: "pct" },
        { label: "ratio", value: "r" },
        { label: "probability", value: "p" },
      ],
      footer_props: {
        "disable-pagination": true,
      },
      answer: default_answer,
      formatPct: {
        decimal: ".",
        separator: ",",
        prefix: "",
        suffix: " %",
        precision: 2,
        masked: false,
      },
      binomialStdDevHeader: [
        { text: "σ", value: "sigma" },
        { text: "x", value: "text", align: "end" },
      ],
      poissonHeader: [
        { text: "", value: "name" },
        { text: "Probability", value: "value", align: "end" },
      ],
      integerRulesAllowZero: [
        (v) => !isNaN(Number(v)) || "Valid number is required.",
        (v) => parseInt(v) == v || "Only whole numbers allowed",
        (v) => Number(v) <= Number(this.n) || "Must be less than (n)",
      ],
      integerRulesAllowZeroPaper: [
        (v) => !isNaN(Number(v)) || "Valid number is required.",
        (v) => parseInt(v) == v || "Only whole numbers allowed",
        (v) => Number(v) <= Number(this.paper_n) || "Must be less than (n)",
      ],
      numRules: [
        (v) => !isNaN(Number(v)) || "Valid number is required.",
        (v) => Number(v) > 0 || "Number must be above zero.",
      ],
      integerRules: [
        (v) => !isNaN(Number(v)) || "Valid number is required.",
        (v) => Number(v) > 0 || "Number must be above zero.",
        (v) => parseInt(v) == v || "Only whole numbers allowed",
      ],
      binomial_chart: "",
      poisson_chart: "",
      normal_chart: "",
    };
  },
  methods: {
    useOPSValues: function (item) {
      this.p = item.p;
      this.n_singular = item.case_name;
      this.x_singular = item.event_name;
      this.update_p_prob();
      this.showSearchResult = false;
    },
    queryPaper: function () {
      var self = this;

      self.inProgress = true;

      axiosConfig({
        method: "get",
        url: "/search",
        params: {
          query: this.x_singular + " " + this.n_singular,
        },
      })
        .then(function (result) {
          self.searchData = _.filter(result.data, { rejected: false });
          self.inProgress = false;
          self.showSearchResult = true;
        })
        .catch(function () {
          self.inProgress = false;
        });
    },
    requestSuccess: function () {
      this.message("Request submitted.");
      this.showRequest = false;
    },
    scrollTo: function (refName) {
      var element = this.$refs[refName];
      element.scrollIntoView();
    },
    calc: function (check_p = true) {
      if (!this.$refs.form.validate()) {
        this.message("Invalid input detected.");
        return;
      }

      if (check_p) {
        if (this.p > 0.5) {
          this.showSuggestedValue = true;
          return;
        }
      }

      var self = this;

      this.showNormalDist = false;
      this.inProgress = true;
      this.binomial_chart = "";
      this.poisson_chart = "";

      return axiosConfig({
        method: "get",
        url: "/calc",
        params: {
          x: this.x,
          p: this.p,
          n: this.n,
          x_singular: this.x_singular,
          n_singular: this.n_singular,
          desirable: this.desirable ? 1 : 0,
          download_key: this.download_key,
        },
      })
        .then(function (result) {
          // console.log(result);
          self.answer = result.data;
          self.binomial_chart = self.answer.binomial_chart;
          self.poisson_chart = self.answer.poisson_chart;
          self.normal_chart = self.answer.normal_chart;
          // self.ci_chart = self.answer.ci_chart;
          self.inProgress = false;
          sleep(250).then(() => {
            if (self.hasReport) {
              self.scrollTo("top");
            } else {
              self.scrollTo("compareproportion");
            }
          });
        })
        .catch((err) => {
          if (err.response.status == 429) {
            self.message("Limited to 20 benchmark per user per day.");
          } else {
            self.message("Error with calculation. Code:" + err.respons.status);
          }
          self.inProgress = false;
          self.answer = default_answer;
          self.binomial_chart = null;
          self.poisson_chart = null;
          self.normal_chart = null;
          // self.ci_chart = null
        });
    },
    pctToString: function (value, precision = 4, absolute_zero = false) {
      if (!isUndefined(value)) {
        var x = value * 100;

        if (x == 0) {
          return "0%";
        } else if (x < 0.0001) {
          if (!absolute_zero) {
            return "< 0.0001%";
          }
        }

        return humanize(x.toFixed(precision)) + "%";
      }
      return "";
    },
    valueToInteger: function (value) {
      if (!isUndefined(value)) {
        if (value !== null && value !== "") {
          try {
            return value.toFixed(0);
          } catch {
            return value;
          }
        }
      }
      return value;
    },
    numToPrecision: function (value, precision = 4) {
      if (!isUndefined(value)) {
        if (value !== null) {
          return humanize(value.toFixed(precision));
        }
      }
      return "";
    },
    message: function (msg) {
      this.snackbarText = msg;
      this.snackbar = true;
    },
    shareLink: function () {
      var self = this;
      var element = document.getElementById("linkURL");
      copyContent2Clipboard2(element).then(() => {
        self.message("Link copied to clipboard.");
      });
    },
    copyDivContentToClipboard: function (divName) {
      var self = this;
      var element = document.getElementById(divName);
      copyDiv2Clipboard2(element).then(() => {
        self.message("Copied to clipboard.");
      });
    },
    update_p_prob: function () {
      this.p_pct = null;
      this.p_ratio = null;

      try {
        this.p_pct = parseFloat(this.p) * 100;
        var ratio = new Fraction(this.p);
        this.p_ratio = ratio.toFraction().replace("/", ":");
      } catch (e) {
        //console.log(e)
      }
    },
    update_p_ratio: function () {
      this.p = null;
      this.p_pct = null;

      try {
        var a = this.p_ratio.split(":");

        if (a[0] == "" || a[1] == "" || a.length < 2) {
          return;
        }
        this.p = parseInt(a[0]) / parseInt(a[1]);
        this.p_pct = this.p * 100;
      } catch (e) {
        //console.log(e)
      }
    },
    update_p_pct: function () {
      this.p = null;
      this.p_ratio = null;

      if (this.p_pct.length <= 0) {
        return;
      }

      try {
        this.p = parseFloat(this.p_pct) / 100;
        this.p_ratio = this.p_pct + ":100";
      } catch (e) {
        //console.log(e)
      }
    },
    copySummary: function () {
      var self = this;
      var element = document.getElementById("summary");
      copyContent2Clipboard2(element).then(() => {
        self.message = "Summary copied to clipboard.";
      });
    },
    showEx: function () {
      this.showExamples = true;
    },
    // showExample: function (ex) {
    //   this.n = ex.n;
    //   this.x = ex.x;
    //   this.p = ex.p;
    //   this.update_p_prob();
    //   this.x_singular = ex.x_singular;
    //   this.n_singular = ex.n_singular;
    //   this.calc();
    //   this.showExamples = false;
    // },
    rejectSwap: function () {
      this.showSuggestedValue = false;
      this.calc(false);
    },
    acceptSwap: function () {
      this.x = this.n - this.x;
      this.p = parseFloat(this.numToPrecision((100 - this.p_pct) / 100));
      this.x_singular = "no " + this.x_singular;
      this.desirable = !this.desirable;
      this.showSuggestedValue = false;
      this.update_p_prob();
      this.calc(false);
    },
    row_classes: function (item) {
      if (item.sigma == "mean") {
        return "highlightcolor";
      }
      return "";
    },
    reloadPage: function () {
      this.$router.push("/");
      window.location.reload();
    },
    getPubMed: function () {
      var self = this;
      getPMID(this.pmid).then((result) => {
        var paper = parsePMIDXML(result);
        // self.paper.paper_title = paper.paper_title;
        // self.paper.paper_abstract = paper.paper_abstract;
        // self.paper.paper_author = paper.paper_author;
        // self.paper.paper_year = paper.paper_year;
        // self.paper.term_dict = paper.paper_mesh
        self.pubmedPaperCitation = `Title: ${paper.paper_title}

Year: ${paper.paper_year}

Author(s): ${paper.paper_author}

Abstract:
${paper.paper_abstract}
`;
      });
    },

    submitToOPS: function () {
      if (!this.$refs.submitpaper.validate()) {
        this.message("Invalid input detected.");
        return;
      }

      var self = this;

      this.inProgressPaper = true;

      var bodyFormData = new FormData();
      bodyFormData.append("pmid", this.pmid);
      bodyFormData.append("p", this.p);
      bodyFormData.append("email", this.pubmedContributorEmail);
      bodyFormData.append("n", this.paper_n);
      bodyFormData.append("x", this.paper_x);
      bodyFormData.append("case_name", this.n_singular);
      bodyFormData.append("event_name", this.x_singular);
      bodyFormData.append("desirable", this.desirable ? 1 : 0);

      return axiosConfig({
        method: "post",
        url: "/submit_paper",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (result) {
          var status = result.data.status;
          if (status == "success") {
            self.highResURL = result.data.url;
            self.showFindingsSubmitSuccess = true;
          } else {
            self.message("Findings submission failed. Try again later.");
          }
          self.inProgressPaper = false;
        })
        .catch(function () {
          self.inProgressPaper = false;
          return null;
        });
    },
  },
};

// sleep time expects milliseconds
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

function humanize(x) {
  return x.replace(/\.?0*$/, "");
}
</script>

<style>
.highlightcolor {
  background-color: gold;
}

.nowrap {
  word-break: keep-all;
}
</style>