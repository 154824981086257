<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="data"
          @click:row="rowClicked"
          :items-per-page="25"
          item-key="findingid"
          group-by="paperid"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              @click="calc(item)"
              style="text-transform: unset !important;"
              small
              >Use</v-btn
            >
          </template>


          <template v-slot:[`item.validated`]="{ item }">
            <v-icon
              small
              :color="item.validated == true ? 'green' : 'grey lighten-2'"
              >mdi-check-circle</v-icon
            >&nbsp;
          </template>          
          <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
            <th :colspan="headers.length + 1">
              <v-icon @click="toggle" 
                >{{ isOpen ? "mdi-minus-box-outline" : "mdi-plus-box-outline" }}
              </v-icon> &nbsp;
              <span class="text-subtitle-1">{{ items[0].paper_title }}</span>
          
            </th>
          </template>
          <template v-slot:[`item.p`]="{ item }">
            {{ pctToString(item.p) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row
      ><v-col>
        <div>
          *This feature is still under development. More details will be made
          available soon.
        </div>
      </v-col></v-row
    >
  </v-container>
</template>

<script>
import { isUndefined } from "../utils";
export default {
  props: {
    data: Array,
  },
  data: function () {
    return {
      singleExpand: true,
      isExpanded: false,
      expand: false,

      headers: [
        { text: "Select", value: "action" },
        { text: "Validated", value:"validated"},
        {
          text: "Event Name (x)",
          align: "start",
          sortable: false,
          value: "event_name",
        },
        { text: "Procedure Name (n)", value: "case_name" },
        { text: "Procedures (n)", value: "n", align: "end" },
        { text: "Events (x)", value: "x", align: "end" },
        { text: "Proportion (p)", value: "p" },
      ],
    };
  },
  methods: {
    calc: function (item) {
      this.$emit("use", item);
    },
    rowClicked: function (item) {
      this.$emit("rowClicked", item);
    },

    pctToString: function (value, precision = 4, absolute_zero = false) {
      if (!isUndefined(value)) {
        var x = value * 100;

        if (x == 0) {
          return "0%";
        } else if (x < 0.0001) {
          if (!absolute_zero) {
            return "< 0.0001%";
          }
        }

        return humanize(x.toFixed(precision)) + "%";
      }
      return "";
    },
  },
};

function humanize(x) {
  return x.replace(/\.?0*$/, "");
}
</script>

<style>
</style>