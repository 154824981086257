var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"items-per-page":25,"item-key":"findingid","group-by":"paperid"},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"text-transform":"unset !important"},attrs:{"small":""},on:{"click":function($event){return _vm.calc(item)}}},[_vm._v("Use")])]}},{key:"item.validated",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.validated == true ? 'green' : 'grey lighten-2'}},[_vm._v("mdi-check-circle")]),_vm._v("  ")]}},{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus-box-outline" : "mdi-plus-box-outline")+" ")]),_vm._v("   "),_c('span',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(items[0].paper_title))])],1)]}},{key:"item.p",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pctToString(item.p))+" ")]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('div',[_vm._v(" *This feature is still under development. More details will be made available soon. ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }