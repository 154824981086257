<template>
  <v-card>
    <v-card-title> Examples </v-card-title>

    <v-card-text>
      <v-container>
        <v-data-iterator :items="examples" item-key="id">
          <template v-slot:default="{ items }">
            <v-row
              ><v-col xs="12" sm="6" md="4" lg="4" xl="4" v-for="item in items" :key="item.name">
                <v-card max-width="100%" min-height="200px">
                  <v-card-title><span style="word-break: keep-all"> {{ item.title }}</span></v-card-title>
                  <v-card-subtitle
                    >n:{{ item.n }} x:{{ item.x }} p:{{
                      item.p
                    }}</v-card-subtitle
                  >
                  <v-card-text>
                    <v-container class="ml-1">
                      <v-row
                        ><v-col><div v-html="item.subtitle"></div></v-col></v-row
                      >
                      <v-row
                        ><v-col align="center"
                          ><v-btn small @click="showExamples(item)"
                            >Show Me</v-btn
                          ></v-col
                        ></v-row
                      >
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-col></v-row
            >
          </template>
        </v-data-iterator>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    navigateToExample: Boolean
  },
  data: function () {
    return {
      examples: [
        {
          id: "a",
          p: 0.5,
          x: 10,
          n: 20,
          title: "Fair coin flip",
          x_singular: "head",
          n_singular: "coin flip",
        },
        {
          id: "b",
          p: 0.5,
          x: 6,
          n: 20,
          x_singular: "head",
          n_singular: "coin flip",
          title: "Unlikely coin flip",
          subtitle: "Is this luck alone?",
        },
        {
          id: "c",
          p: 0.5,
          x: 0,
          n: 20,
          x_singular: "head",
          n_singular: "coin flip",
          title: "Extremely unlikely outcome coin flip",
          subtitle: "Is this a biased coin?",
        },
        {
          id: "d",
          p: 0.15,
          x: 2,
          n: 80,
          x_singular: "sepsis",
          n_singular: "procedure",
          title: "Red hot surgeon",
          subtitle: "3 sigma below",
        },
        {
          id: "e",
          p: 0.15,
          x: 25,
          n: 80,
          x_singular: "sepsis",
          n_singular: "procedure",
          title: "Something is truly amiss",
          subtitle: "3 sigma above",
        },
        {
          id: "f",
          p: 0.15,
          x: 1,
          n: 40,
          x_singular: "sepsis",
          n_singular: "procedure",
          title: "Not enough cases",
          subtitle: "Come back when they have at least 67 cases",
        },
        {
          id: "g",
          p: 0.001,
          x: 0,
          n: 500,
          x_singular: "event",
          n_singular: "case",
          title: "Rare Event",
          subtitle:
            "Unlikely to ever have enough numbers, approximation to binomial that is skewed right",
        },

        {
          id: "h",
          p: 0.25,
          x: 5,
          n: 50,
          x_singular: "failure",
          n_singular: "pneumatic",
          title: "Pneumatic Retinopexy",
          subtitle:
            "Example based on the paper published <a href='https://pubmed.ncbi.nlm.nih.gov/18929759/' target='_blank'>here</a>",
        }
      ],
    };
  },
  methods: {
    getValuesURL: function (ex) {
      return (
        `/?n=${encodeURI(ex.n)}&p=${encodeURI(ex.p)}&x=${encodeURI(
          ex.x
        )}&x_singular=${encodeURI(ex.x_singular)}&n_singular=${encodeURI(
          ex.n_singular
        )}`
      );
    },    
    showExamples: function (ex) {
      if (this.navigateToExample){
        this.$router.push(this.getValuesURL(ex))
        window.location.reload()
      }else{

        this.$emit("showExample", ex);
      }
    },
  },
};
</script>

<style>
</style>