var ax = require('./axios');
var axiosConfig = ax.default;

module.exports.isUndefined = function (obj) {
    if (typeof obj == "undefined") {
        return true;
    } else {
        return false;
    }
}

module.exports.copyContent2Clipboard2 = async function (el) {
    el.select();
    el.setSelectionRange(0, 99999);
    return await navigator.clipboard.writeText(el.value).then(() => {
        //Deselect text
        if (window.getSelection) {
            if (window.getSelection().empty) {
                // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) {
                // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) {
            // IE?
            document.selection.empty();
        }
    });
}


module.exports.copyDiv2Clipboard2 = async function (el) {
    return await navigator.clipboard.writeText(el.outerText).then(() => {
        return
    });
}


module.exports.getPMID = function (pmid) {

    return axiosConfig({
        method: "get",
        url: "https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi",
        params: {
            db: 'pubmed',
            id: pmid,
            retmode: 'xml'
        },
        withCredentials: false
    })
        .then(function (result) {
            var obj = new DOMParser();
            var parseObj = obj.parseFromString(result.data, "text/xml")
            return parseObj
        })
}

module.exports.parsePMIDXML = function (xmlObj) {

    const ARTICLE_ROOT = '//PubmedArticleSet/PubmedArticle/MedlineCitation/Article'

    var paper = {}

    var title = xmlObj.evaluate(ARTICLE_ROOT + "/ArticleTitle/text()", xmlObj, null, XPathResult.STRING_TYPE, null)
    var year = xmlObj.evaluate("/PubmedArticleSet/PubmedArticle/PubmedData/History/PubMedPubDate[1]/Year/text()", xmlObj, null, XPathResult.STRING_TYPE, null)
    var abstracts = xmlObj.evaluate(ARTICLE_ROOT + "/Abstract/*", xmlObj, null, XPathResult.ANY_TYPE, null)
    var authors = xmlObj.evaluate(ARTICLE_ROOT + "/AuthorList/*", xmlObj, null, XPathResult.ANY_TYPE, null)
    var meshs = xmlObj.evaluate('//PubmedArticleSet/PubmedArticle/MedlineCitation/MeshHeadingList/*', xmlObj, null, XPathResult.ANY_TYPE, null)

    var ab = abstracts.iterateNext()
    var abstractList = []

    while (ab !== null) {
        if (ab.attributes.length>0){
            abstractList.push(ab.attributes['Label']['nodeValue'] + ' - ' + ab.innerHTML)
        }else{
            abstractList.push(ab.innerHTML)
        }
        ab = abstracts.iterateNext()
    }

    var authorList = []
    var au = authors.iterateNext()

    while (au !== null) {
        var ln = ''
        var fn = ''

        for (let cn of au.childNodes) {
            if (cn.nodeName == 'LastName') {
                ln = cn.innerHTML

            } else if (cn.nodeName == 'ForeName') {
                fn = cn.innerHTML
            }
        }

        authorList.push(ln + ', ' + fn)
        au = authors.iterateNext()
    }



    var meshList = []
    var mh = meshs.iterateNext()

    while (mh !== null) {
        for (let cn of mh.childNodes) {
            if (cn.nodeName == 'DescriptorName'){
                meshList.push({id:cn.attributes['UI']['nodeValue'],display_name:cn.innerHTML})
            }
        }
        mh = meshs.iterateNext()
    }    


    paper['paper_title'] = title.stringValue
    paper['paper_abstract'] = abstractList.join('\n\n')
    paper['paper_author'] = authorList.join('; ')
    paper['paper_year'] = year.stringValue
    paper['paper_mesh'] = meshList

    return paper

}

module.exports.weightedRandom =function(items, weights) {
    if (items.length !== weights.length) {
      throw new Error('Items and weights must be of the same size');
    }
  
    if (!items.length) {
      throw new Error('Items must not be empty');
    }
  
    // Preparing the cumulative weights array.
    // For example:
    // - weights = [1, 4, 3]
    // - cumulativeWeights = [1, 5, 8]
    const cumulativeWeights = [];
    for (let i = 0; i < weights.length; i += 1) {
      cumulativeWeights[i] = weights[i] + (cumulativeWeights[i - 1] || 0);
    }
  
    // Getting the random number in a range of [0...sum(weights)]
    // For example:
    // - weights = [1, 4, 3]
    // - maxCumulativeWeight = 8
    // - range for the random number is [0...8]
    const maxCumulativeWeight = cumulativeWeights[cumulativeWeights.length - 1];
    const randomNumber = maxCumulativeWeight * Math.random();
  
    // Picking the random item based on its weight.
    // The items with higher weight will be picked more often.
    for (let itemIndex = 0; itemIndex < items.length; itemIndex += 1) {
      if (cumulativeWeights[itemIndex] >= randomNumber) {
        return {
          item: items[itemIndex],
          index: itemIndex,
        };
      }
    }
  }

  module.exports.titleCase = function(str) {
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }