<template>
  <div>
    <v-text-field
      v-model="casesPerWeek"
      :label="caseName + ' per week'"
      dense
      outlined
    >
    </v-text-field>
    <v-slider
      class="mt-3"
      dense
      label="Remaining years of surgery"
      hint="Year remaining"
      max="50"
      min="1"
      v-model="remainingYears"
      thumb-label="always"
      :thumb-size="24"
    ></v-slider>
    <p><v-btn @click="refreshCalculation">Simulate</v-btn></p>
    <p>
      <span class="text-h6">Time elapsed: {{ timeElapsed }}</span>
    </p>
    <div class="text-subtitle-1">
      <p>{{ eventName }} in:</p>
    </div>
    <v-container>
      <v-row>
        <v-col>
          <p>
            <span class="text-h6">Surgeon's: {{ pctToString(userP) }}</span>
          </p>
          <p>
            1 week: {{ sumEventsPerDuration("1w") }} in {{ countEvents("1w") }}
            {{ n_plural }}
          </p>

          <p>
            1 month: {{ sumEventsPerDuration("1m") }} in
            {{ countEvents("1m") }} {{ n_plural }}
          </p>
          <p>
            1 year: {{ sumEventsPerDuration("1y") }} in {{ countEvents("1y") }}
            {{ n_plural }}
          </p>
          <p>
            Lifetime:
            {{ sumEventsPerDuration("lt") }} in {{ countEvents("lt") }}
            {{ n_plural }}
          </p>
        </v-col>
        <v-col>
          <p>
            <span class="text-h6">Benchmark: {{ pctToString(p) }}</span>
          </p>
          <p>
            1 week: {{ sumEventsPerDuration("1wp") }} in
            {{ countEvents("1wp") }}
            {{ n_plural }}
          </p>

          <p>
            1 month: {{ sumEventsPerDuration("1mp") }} in
            {{ countEvents("1mp") }} {{ n_plural }}
          </p>
          <p>
            1 year: {{ sumEventsPerDuration("1yp") }} in
            {{ countEvents("1yp") }} {{ n_plural }}
          </p>
          <p>
            Lifetime:
            {{ sumEventsPerDuration("ltp") }} in {{ countEvents("ltp") }}
            {{ n_plural }}
          </p>
        </v-col>
      </v-row></v-container
    >
  </div>
</template>

<script>
import { isUndefined, titleCase, weightedRandom } from "../utils";
const _ = require("lodash");
const stepSize = 5;
import moment from "moment";

export default {
  props: {
    p: Number,
    userP: Number,
    x_singular: String,
    n_singular: String,
    x_plural: String,
    n_plural: String,
  },
  computed: {
    eventName: function () {
      var a = this.casesPerWeek > 1 ? this.x_plural : this.x_singular;
      if (isUndefined(a)) {
        return "";
      }

      return titleCase(a);
    },
    caseName: function () {
      var a = this.casesPerWeek > 1 ? this.n_plural : this.n_singular;

      if (isUndefined(a)) {
        return "";
      }

      return titleCase(a);
    },
    timeElapsed: function () {
      if (this.currentWeek > 0) {
        return moment.duration(this.currentWeek, "weeks").humanize();
      }
      return "";
    },
  },

  data: () => {
    return {
      remainingYears: 35,
      casesPerWeek: 10,
      currentWeek: 0,
      simulatedEvents: {},
      currentTimer: null,
    };
  },

  methods: {
    refreshCalculation: function () {
      var self = this;

      if (this.currentWeek >= 0) {
        window.clearInterval(this.currentTimer);
        this.currentWeek = 0;
        this.simulatedEvents = {};
      }

      if (isUndefined(this.p) || isUndefined(this.userP)) {
        return null;
      }

      this.currentTimer = window.setInterval(() => {
        self.eventsPerDuration("lt", self.userP, 52 * self.remainingYears);
        self.eventsPerDuration("1y", self.userP, 52);
        self.eventsPerDuration("1m", self.userP, 4);
        self.eventsPerDuration("1w", self.userP, 1);

        self.eventsPerDuration(
          "ltp",
          self.p,
          52 * parseInt(self.remainingYears)
        );
        self.eventsPerDuration("1yp", self.p, 52);
        self.eventsPerDuration("1mp", self.p, 4);
        self.eventsPerDuration("1wp", self.p, 1);

        self.currentWeek = self.currentWeek + (1 * stepSize);

        if (self.currentWeek > self.remainingYears * 52) {
          window.clearInterval(self.currentTimer);
        }

        var cpw = self.casesPerWeek;
        self.casesPerWeek = 0;
        self.casesPerWeek = cpw;
      }, 25);
    },

    eventsPerDuration: function (id, p, weeks) {
      var self = this;
      var maxCases = weeks * this.casesPerWeek;
      var p1 = parseFloat(p)

      if (isUndefined(self.simulatedEvents[id])) {
        self.simulatedEvents[id] = [];
      }

      _.map(Array(this.casesPerWeek * stepSize), () => {
        self.simulatedEvents[id].push(weightedRandom([0, 1], [1 - p1, p1]));
        return "";
      });

      if (this.simulatedEvents[id].length > maxCases) {
        this.simulatedEvents[id] = this.simulatedEvents[id].slice(0, maxCases);
      }
    },

    sumEventsPerDuration: function (id) {
      var eventCount = _.map(this.simulatedEvents[id], (f) => {
        return f.item == 1 ? 1 : 0;
      });
      return _.sum(eventCount);
    },

    countEvents: function (id) {
      if (isUndefined(this.simulatedEvents[id])) {
        return null;
      }
      return this.simulatedEvents[id].length;
    },

    pctToString: function (value, precision = 4, absolute_zero = false) {
      if (!isUndefined(value)) {
        var x = value * 100;

        if (x == 0) {
          return "0%";
        } else if (x < 0.0001) {
          if (!absolute_zero) {
            return "< 0.0001%";
          }
        }

        return humanize(x.toFixed(precision)) + "%";
      }
      return "";
    },
  },
};

function humanize(x) {
  return x.replace(/\.?0*$/, "");
}
</script>



<style>
</style>