<template>
  <v-container fluid>
    <v-row
      ><v-col>
        <p class="ma-n1">
          <router-link to="/"
            ><v-img max-width="100px" src="3sigma.png"></v-img
          ></router-link>
        </p>
        <p class="text-h5">3 Sigma Surgeon</p>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col align="left">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Contents:</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><a @click="scrollTo('intro')"
                  >1. Introduction</a
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><a @click="scrollTo('stepbystep')"
                  >2. Step By Step Explanation</a
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                ><a @click="scrollTo('reallife')"
                  >3. Real Life Examples</a
                ></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>

        </v-list>

        <v-divider></v-divider>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col align="left">
        <p ref="intro" class="text-h6 mt-6 header-anchor">Introduction</p>

        <p>
          You are told a surgery has a 50% chance of failing. After performing
          the surgery 20 times, you had 2 failures in 20 surgeries.
        </p>
        <p>
          What is the 95% confidence interval of your percentage of surgical
          failure?
        </p>

        <p>
          What are the odds that you just had a lucky streak and this is simply
          a chance occurrence?
        </p>
        <p>
          Are 20 surgeries enough for you to make a meaningful conclusion about
          your performance?
        </p>
        <p>Click on the example button below to see 3σ in action</p>
        <p>
          <v-btn @click="showExamples = true">Show Examples</v-btn>
        </p>
        <p>or</p>
        <p>
          see a detailed step by step guide on how to use this calculator below.
        </p>
        <p></p>
      </v-col>
    </v-row>
    <v-row
      ><v-col align="left">
        <v-divider></v-divider>
        <p ref="stepbystep" class="text-h6 mt-6">Step By Step Explanation</p>

        <p>
          You are told a surgery has a 50% chance of failing. After performing
          the surgery 20 times, you had 2 failures in 20 surgeries.
        </p>

        <p>Your percentage of failure is:</p>

        <p>2/20=10%</p>

        <p>
          On cursory comparison, one might conclude their percentage of failure
          of 10% is much better than the reported 50%.
        </p>

        <p>It is possible you are simply having a lucky streak?</p>

        <p>We have replicated the scenario on 3σ and let's see 3σ in action:</p>

        <p><v-img src="/img/input.png" max-width="600px"></v-img></p>
        <p>
          Firstly, instead of a simple 10% failure, 3σ provides you with a
          <span style="text-decoration: underline"
            >95% confidence interval</span
          >
          of your failure percentage.
        </p>
        <p>
          <v-img src="/img/proportion_estimate.png" max-width="600px"></v-img>
        </p>

        <p>
          Here we note the confidence interval does not contain the reference
          failure percentage of 50%, providing us with the first clue that your
          rate of failure is significantly different from the reported 50%
          failure.
        </p>
        <p>
          If we had more surgeries but the same proportion of failure, e.g., 200
          in 2000, our confidence interval will narrow significantly to 8.7617%
          and 11.3980%; in other words the more surgeries we have, the more
          precise we are in estimating where our true percentage is.
        </p>

        <p>
          Next, 3σ will
          <span style="text-decoration: underline">quantify the chance</span> of
          observing 2 or fewer failures after 20 surgeries if the chance of
          failure is truly 50%.
        </p>

        <p><v-img src="/img/pct_of_failure.png" max-width="600px"></v-img></p>

        <p>
          Here 3σ tells us the chance of this occurring is only 0.0201%, giving
          us a sense of how rare our observation is. This is analogous to a coin
          toss experiment. If we flipped a coin 20 times, what are the
          probabilities of seeing 10 or fewer heads, 6 or fewer heads, or 0
          heads? 41.1901%, 5.7659%, &#60; 0.0001%. Either we are super unlucky
          not to get a single head after 20 coin toss OR we have a biased coin
          or a technique that can consistently get us tails. Similarly, if the
          chance of this surgery failing is truly 50%, and we only have 2
          failures after 20 surgeries, we are either very lucky OR our rate of
          failure is lower than the reference 50%.
        </p>

        <p>
          By default, 3σ reports the chance of seeing 2 or fewer failures but
          the next section provides the additional probabilities of observing
          less than 2 and exactly 2 failures.
        </p>

        <p>
          <v-img src="/img/prob.png" max-width="400px"></v-img>
        </p>

        <p>
          Finally, 3σ will plot the probability of observing 0 failure, 1
          failure, 2 failures... all the way to 20 failures on a graph
          (probability density function graph). The blue line corresponds to
          observing 2 failures and each bar represents the probability of
          observing x number of failures. Notice how the shape of the graph
          looks very similar to a normal distribution? When your graph assumes a
          normal distribution, we can be confident we are capturing 99.7% of all
          possible outcomes.
        </p>

        <p>
          <v-img src="/img/pdf_chart.png" max-width="400px"></v-img>
        </p>

        <p>
          In the next section, 3σ will check if your probability distribution is
          normally distributed by automatically checking if you have enough
          cases. e.g.:
        </p>

        <p>
          <v-img src="/img/approx_norm.png" max-width="600px"></v-img>
        </p>

        <p>
          If you did not have enough cases, 3σ will advise how many cases you
          need. 3σ will still try to approximate your curve to the normal
          distribution but you can still see the preliminary and less accurate
          modeling by clicking on the "Show Preliminary Modelling" button. e.g.:
        </p>
        <p>
          <v-img
            src="/img/insufficient_for_normal.png"
            max-width="600px"
          ></v-img>
        </p>

        <p>
          The 3σ rule tells us that for a normal distribution, almost all
          observed data will fall within three standard deviations of the mean
          or average. Here the 3σ rule is represented in a table form
          accompanied by a text summary:
        </p>

        <p>
          <v-img src="/img/sd_table.png" max-width="300px"></v-img>
        </p>
        <p>
          <v-img src="/img/sd_text.png" max-width="400px"></v-img>
        </p>
        <p>
          Finally, you are presented with the graphical representation of your
          distribution and its text summary:
        </p>

        <p>
          <v-img src="/img/cdf_chart.png" max-width="400px"></v-img>
        </p>
        <p>
          <v-img src="/img/cdf_summary_text.png" max-width="600px"></v-img>
        </p>

        <p>
          To conclude the analysis, 3σ presents you with a simple plain English
          summary:
        </p>

        <p>
          <v-img src="/img/summary_text.png" max-width="600px"></v-img>
        </p>
        <v-divider></v-divider>

        <p ref="reallife" class="text-h6 mt-6">Real-life Example</p>

        <p>
          You have recently completed an audit on a series of patients having
          pneumatic retinopexy at your institute. You have a total of 50 cases
          and 5 of these procedures failed; giving a 10% failure rate.
        </p>
        <p>
          You looked up the literature to see how you compare to the published
          results and found the expected failure rate is ~25%.
        </p>

        <p>
          <v-img src="/img/example1.png" max-width="600px"></v-img>
        </p>

        <p>
          How did you perform? Are you simply having a lucky streak? Click
          <router-link
            to="/?n=50&p=0.25&x=5&x_singular=failure&n_singular=pneumatic%20retinopexy"
            >here</router-link
          >
          to see 3σ in action:
        </p>

        <p>
          <v-img src="/img/example2.png" max-width="600px"></v-img>
        </p>

        <p>
          You can summarise confidently that you have enough numbers to ensure
          this is not a fluke and your percentage is lower than the published
          rates.
        </p>
      </v-col></v-row
    >

    <v-dialog v-model="showExamples">
      <tutorial v-bind:navigateToExample="true"></tutorial>
    </v-dialog>
  </v-container>
</template>

<script>
import Tutorial from "../components/Tutorial.vue";
export default {
  components: { Tutorial },
  data: function () {
    return {
      showExamples: false,
    };
  },
  methods: {
    scrollTo: function (refName) {
      var element = this.$refs[refName];
      element.scrollIntoView();
    },
  },
};
</script>

<style>
.header-anchor {
  border-bottom: 1px solid #eaecef;
}
</style>