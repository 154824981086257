<template>
  <v-card>
    <v-card-title>Request for Publication Ready Graphs</v-card-title>
    <v-card-text>
      <v-container>
        <v-row
          ><v-col>
            <p>
              High-resolution watermark-free graphs are available for donors.
            </p>
            <p>
              If you're interested in obtaining them, please enter your Paypal
              donation transaction ID and email address below:
            </p>
          </v-col></v-row
        >
        <v-row
          ><v-col>
            <v-text-field
              autofocus
              outlined
              dense
              v-model="email"
              label="Email"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="transactionId"
              label="Paypal Transacion ID"
            ></v-text-field> </v-col
        ></v-row>
        <v-row
          ><v-col>
            <p class="mt-0 mb-0"><b>Your input:</b></p>
            <p class="mb-n1">p={{ p }}, n={{ n }}, x={{ x }}</p>
            <p class="mb-n1">Case Name: {{ n_singular }}</p>
            <p>Event Name: {{ x_singular }}</p>
            <!-- <p>Event is <span v-show="!desirable">not</span> desirable</p> -->

            <v-btn @click="request">Request</v-btn> &nbsp;
            <v-progress-circular
              indeterminate
              v-show="inProgress"
              size="20"
            ></v-progress-circular> </v-col
        ></v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosConfig from "../axios";
export default {
  props: {
    n: Number,
    p: Number,
    x: Number,
    n_singular: String,
    x_singular: String,
    desirable: Boolean,
  },
  data: function () {
    return {
      inProgress: false,
    };
  },
  methods: {
    request: function () {
      var self = this;
      this.inProgress = true;

      var bodyFormData = new FormData();
      bodyFormData.append("x", this.x);
      bodyFormData.append("n", this.n);
      bodyFormData.append("p", this.p);
      bodyFormData.append("x_singular", this.x_singular);
      bodyFormData.append("n_singular", this.n_singular);
      bodyFormData.append("desirable", this.desirable ? 1 : 0);
      bodyFormData.append("email", this.email);
      bodyFormData.append("transactionid", this.transactionId);

      return axiosConfig({
        method: "post",
        url: "/request",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then(() => {
        self.$emit("success", true);
        this.inProgress = false;
      });
    },
  },
};
</script>

<style>
</style>